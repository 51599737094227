import { AbstractEvent } from '@kluseg/trackify/dist/main'
import { CustomAnalyticsEvent } from '@kluseg/trackify/dist/interfaces/analytics-event'

import { ConsentsConfig } from '../contracts'

export class ConsentsEvent extends AbstractEvent implements CustomAnalyticsEvent<ConsentsConfig> {
  name = 'custom.consents'

  constructor (protected config: ConsentsConfig) {
    super(config)
  }

  forDriver (driverName: string) {
    if (driverName === 'UsercomBrowserDriver') {
      return {
        event_name: 'event.newsletterSubscribe',
        event_payload: this.getData(),
        event_type: 'form'
      }
    } else if (driverName === 'GetResponseBrowserDriver') {
      return {
        event_name: 'newsletter_subscribe',
        event_payload: this.getData(),
      }
    }
    return null
  }

  getData (): ConsentsConfig {
    return {
      email: this.config.email,
      first_name: this.config.firstName,
      last_name: this.config.lastName,
      language: this.config.language,
      occupation: this.config.occupation,
      accept_content: this.config.acceptContent,
      accept_privacy: this.config.acceptPrivacy,
      allow_direct_marketing: this.config.directMarketing ?? 'false',
      allow_marketing: this.config.emailOffers ?? 'false',
      allow_partners_marketing: this.config.partnersOffers ?? 'false',
      allow_sms_marketing: this.config.smsOffers ?? 'false',
      general_form_consent: this.config.generalFormConsent
    }
  }
}
